<template>
<v-card>
  <v-card-title>Update details</v-card-title>
  <v-data-table
    :headers="updatedHeaders"
    :items="updated"
    :disable-pagination="true"
    :hide-default-footer=true
    dense>
  </v-data-table>
  <!-- Grouped example -->
  <!-- <v-data-table :headers="updatedHeaders" :items="updated" hide-default-footer item-key="field" group-by="sku">
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                <v-icon v-if="!isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            {{ group }}
        </td>
    </template>
  </v-data-table> -->
</v-card>
</template>

<script>

export default {
  props: {
    updated: Array
  },
  data () {
    return {
      updatedHeaders: [
        { text: 'Product', value: 'sku' },
        { text: 'Field', value: 'field' },
        { text: 'Old', value: 'old' },
        { text: 'New', value: 'new' }
      ]
    }
  }
}
</script>
